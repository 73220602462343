@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

.sidebar {
    @extend .col-sm-3;
    background-color: #f5f5f5;
}
.navbar {
    border-radius: 0;
    margin-bottom: 0;
}
.main {
    @extend .col-sm-9;
}

@media print {
    .sidebar {
        display: none;
    }
    .main {
        width: 100%;
    }
    .pagination {
        display: none;
    }
}
